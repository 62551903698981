<template>
  <div class="rapairs-nav">
    <router-link to="/repairs/add-new-repair" class="link"
      >добавить ремонт</router-link
    >
    <router-link to="/repairs/repairs-list" class="link"
      >список ремонтов</router-link
    >
    <router-view />
  </div>
</template>

<script>
export default {
  name: "Repairs"
};
</script>
<style lang="scss" scoped>
.link {
  padding-left: 10px;
}
.rapairs-nav {
  padding: 5px;
}
</style>
